<template>
  <div class="card stock-modal" :class="{'is-shadowless': !isModal}">
    <div class="card-content">
      <header class="mb-5">
        <div class="is-flex is-justify-content-space-between is-align-items-center">
          <h2 class="is-size-6 has-text-primary has-text-weight-bold mr-3">{{ title }}</h2>
          <div @click="$emit('close')" v-if="isModal">
            <b-icon class="cursor-pointer" icon="close-thick"/>
          </div>
        </div>
        <div>
          <p class="has-text-secondary mb-3">{{ selectedFarm.name }}</p>
        </div>
      </header>
      <section class="my-2">

        <ValidationProvider :name="$t('type')" rules="required" v-slot="{ errors }" v-if="!type">
          <b-field :label="`${$t('stock.stock')} ${$t('and')} ${$t('harvest.harvest')}`"
                   :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
            <b-radio v-model="form.type"
                     v-if="canAddStock || canEditStock"
                     name="type"
                     native-value="stock">
              {{ $t('stock.stock') }}
            </b-radio>
            <b-radio v-model="form.type"
                     v-if="canAddHarvest || canEditHarvest"
                     name="type"
                     native-value="harvest">
              {{ $t('harvest.harvest') }}
            </b-radio>
          </b-field>
        </ValidationProvider>

        <ValidationObserver ref="form-stock">
          <form class="mt-3" @submit.prevent="handleSubmit">
            <div v-show="form.type === 'stock'">
              <div class="columns is-tablet">
                <div class="column is-half-tablet" v-if="!defaultValue">
                  <ValidationProvider :name="$t('farm.block')" rules="required" v-slot="{ errors }">
                    <b-field :label="$t('farm.block')" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                      <sg-select :items="selectedFarm.blocks" type="is-secondary is-light"
                                 :disabled="defaultValue ? true : false"
                                 label="block_name"
                                 v-model="form.block"
                                 @change="form.pond = null"
                                 :placeholder="`${$t('select')} ${$t('farm.block')}`" expanded/>
                    </b-field>
                  </ValidationProvider>
                </div>
                <div class="column is-half-tablet">
                  <ValidationProvider :name="$t('farm.pond')" rules="required" v-slot="{ errors }">
                    <b-field :label="$t('farm.pond')" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                      <sg-select :items="filteredPonds" type="is-secondary is-light" v-model="form.pond"
                                 :disabled="defaultValue ? true : false"
                                 label="name"
                                 @change="handleChangePond"
                                 :multiple="multiple"
                                 :placeholder="`${$t('select')} ${$t('farm.pond')}`" expanded/>
                    </b-field>
                  </ValidationProvider>
                </div>

                <div class="column is-full">
                  <ValidationProvider :name="$t('stock.stock_date')" rules="required" v-slot="{ errors }">
                    <b-field :label="$t('stock.stock_date')" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                      <b-datepicker
                          :placeholder="$t('date')"
                          icon="calendar-text"
                          locale="id-ID"
                          v-model="form.stock_date"
                      >
                      </b-datepicker>
                    </b-field>
                  </ValidationProvider>
                </div>

                <div class="column is-full" v-if="!defaultValue && isPro">
                  <ValidationProvider :name="$t('stock.stock_photo')" v-slot="{ errors }">
                    <b-field :label="`${$t('stock.stock_photo')} (${$t('optional')})`"
                             :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                      <div class="is-flex is-flex-direction-column">
                        <b-field class="file is-primary">
                          <div class="is-flex is-align-items-center">
                            <b-upload v-model="file" class="file-label" @input="handleChangeFile" multiple>
                          <span class="file-cta">
                              <b-icon class="file-icon" icon="upload"></b-icon>
                              <span class="file-label">{{ $t('add') }} {{ $t('photo') }}</span>
                          </span>
                            </b-upload>
                            <div class="is-flex" @click="showInformation=!showInformation">
                              <b-icon icon="information-outline" class="ml-2 cursor-pointer"/>
                            </div>
                          </div>
                        </b-field>
                        <div>
                          <p v-show="showInformation">
                            Tambahkan <b>Foto Benur</b> untuk menghitung Benur Aktual secara Otomatis dengan Sgara AI
                            seperti contoh di bawah
                          </p>
                          <img src="/img/assets/sgara-book-count-pl.png" v-show="showInformation"
                               class="has-border my-2"
                               @mouseleave="showInformation=false"/>
                        </div>
                        <b-carousel :autoplay="false" v-if="form.file.length" :arrow-hover="false">
                          <b-carousel-item v-for="(item, i) in form.file" :key="i" class="p-1">
                            <div class="image pl-image card">
                              <div class="card-content p-0">
                                <div class="pl-overlay">
                                  <span>Total PL: <span class="has-text-weight-bold">{{
                                      item[0].total_pl
                                    }}</span></span>
                                  <b-button type="is-danger" icon-left="close" @click="deletePhoto(i)"></b-button>
                                </div>
                                <img :src="item[1].image_url">
                              </div>
                            </div>
                          </b-carousel-item>
                        </b-carousel>
                      </div>
                    </b-field>
                  </ValidationProvider>
                </div>

                <div class="column is-half-tablet" v-if="form.file.length">
                  <ValidationProvider :name="`${$t('avg')} PL`" v-slot="{ errors }">
                    <b-field :label="`${$t('avg')} PL`" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                      <b-input v-model.number="avg_pl" id="avg_pl" placeholder="" disabled/>
                    </b-field>
                  </ValidationProvider>
                </div>

                <div class="column is-half-tablet" v-if="form.file.length">
                  <ValidationProvider :name="`Total ${$t('stock.bag')}`" v-slot="{ errors }">
                    <b-field :label="`Total ${$t('stock.bag')}`" :type="errors[0] ? 'is-danger' : ''"
                             :message="errors[0]">
                      <b-input v-model.number="form.bag" id="bag" :placeholder="`Input Total ${$t('stock.bag')}`"
                               @input="handleChangeBag"/>
                    </b-field>
                  </ValidationProvider>
                </div>

                <div class="column is-half-tablet">
                  <ValidationProvider :name="$t('stock.netto_stock')" rules="required" v-slot="{ errors }">
                    <b-field :label="$t('stock.netto_stock')" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                      <b-input v-model.number="form.netto" id="netto" placeholder="Input Netto"/>
                    </b-field>
                  </ValidationProvider>
                </div>

                <div class="column is-half-tablet">
                  <ValidationProvider :name="$t('stock.actual_stock')" rules="required" v-slot="{ errors }">
                    <b-field :label="$t('stock.actual_stock')" :type="errors[0] ? 'is-danger' : ''"
                             :message="errors[0]">
                      <b-input v-model.number="form.pl_count" id="pl_count" placeholder="Input PL"/>
                    </b-field>
                  </ValidationProvider>
                </div>

                <div class="column is-half-tablet">
                  <ValidationProvider :name="$t('stock.source')" rules="required" v-slot="{ errors }">
                    <b-field :label="$t('stock.source')" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                      <b-input v-model="form.pl_source" id="pl_source" :placeholder="`Input ${$t('stock.source')}`"/>
                    </b-field>
                  </ValidationProvider>
                </div>
              </div>

              <ValidationProvider name="Cost" rules="required" v-slot="{ errors }" v-if="cost">
                <b-field label="Cost" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                  <p class="control">
                    <b-button type="is-text" class="btn-placeholder">Rp</b-button>
                  </p>
                  <p class="control w-100">
                    <b-input v-model.number="form.stock_cost" id="stock_cost" placeholder="Input Cost"/>
                  </p>
                </b-field>
              </ValidationProvider>
            </div>
          </form>
        </ValidationObserver>

        <ValidationObserver ref="form-harvest">
          <form class="mt-3" @submit.prevent="handleSubmit">
            <div v-show="form.type === 'harvest'">
              <p class="has-text-secondary mb-3">{{ $t('farm.no_stock') }}</p>

              <div class="columns is-tablet">

                <div class="column is-half-tablet">
                  <ValidationProvider :name="$t('farm.block')" rules="required" v-slot="{ errors }">
                    <b-field :label="$t('farm.block')" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                      <sg-select :items="selectedFarm.blocks" type="is-secondary is-light"
                                 :disabled="defaultValue ? true : false"
                                 label="block_name"
                                 v-model="form.block"
                                 @change="form.pond = null"
                                 :placeholder="`${$t('select')} ${$t('farm.block')}`" expanded/>
                    </b-field>
                  </ValidationProvider>
                </div>
                <div class="column is-half-tablet">
                  <ValidationProvider :name="$t('farm.pond')" rules="required" v-slot="{ errors }">
                    <b-field :label="$t('farm.pond')" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                      <sg-select :items="filteredPonds" type="is-secondary is-light" v-model="form.pond"
                                 :disabled="defaultValue ? true : false"
                                 label="name"
                                 @change="handleChangePond"
                                 :placeholder="`${$t('select')} ${$t('farm.pond')}`" expanded/>
                    </b-field>
                  </ValidationProvider>
                </div>

                <div class="column is-half-tablet">
                  <div class="card">
                    <div class="card-content p-4">
                      <b-field :label="$t('cycle.current')" class="mb-0">
                        <p>{{ cycle_date }}</p>
                      </b-field>
                    </div>
                  </div>
                </div>
                <div class="column is-half-tablet">
                  <div class="card">
                    <div class="card-content p-4">
                      <b-field :label="$t('stock.stock_date')" class="mb-0">
                        <p>{{ stock_date }}</p>
                      </b-field>
                    </div>
                  </div>
                </div>

                <div class="column is-full">
                  <ValidationProvider :name="$t('harvest.harvest_date')" rules="required" v-slot="{ errors }">
                    <b-field :label="$t('harvest.harvest_date')" :type="errors[0] ? 'is-danger' : ''"
                             :message="errors[0]">
                      <b-datepicker
                          :placeholder="$t('date')"
                          icon="calendar-text"
                          locale="id-ID"
                          v-model="form.harvest_date"
                      >
                      </b-datepicker>
                    </b-field>
                  </ValidationProvider>
                </div>
                <div class="column is-full">
                  <ValidationProvider :name="$t('harvest.harvest_type')" rules="required" v-slot="{ errors }">
                    <b-field :label="$t('harvest.harvest_type')" :type="errors[0] ? 'is-danger' : ''"
                             :message="errors[0]">
                      <b-radio v-model="form.harvest_type"
                               name="harvest_type"
                               native-value="Partial Harvest">
                        {{ $t('harvest.partial') }}
                      </b-radio>
                      <b-radio v-model="form.harvest_type"
                               name="harvest_type"
                               native-value="Total Harvest">
                        Total
                      </b-radio>
                    </b-field>
                  </ValidationProvider>
                </div>
              </div>

              <div class="columns is-tablet">
                <div class="column is-half-tablet">
                  <ValidationProvider name="Size" rules="required" v-slot="{ errors }">
                    <b-field label="Size" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                      <b-input type="number"
                               id="size"
                               step="any"
                               v-model.number="form.size"
                               placeholder="Input Size"
                               @keyup.native="handleSizeChange"
                               expanded/>
                      <p class="control">
                        <b-button type="is-text" class="btn-placeholder">/ Kg</b-button>
                      </p>
                    </b-field>
                  </ValidationProvider>
                </div>
                <div class="column is-half-tablet">
                  <ValidationProvider name="MBW" rules="required" v-slot="{ errors }">
                    <b-field label="MBW" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                      <b-input type="number" step="any" id="mbw" v-model.number="form.mbw" placeholder="Input MBW"
                               @keyup.native="handleMBWChange"
                               expanded/>
                      <p class="control">
                        <b-button type="is-text" class="btn-placeholder">gr</b-button>
                      </p>
                    </b-field>
                  </ValidationProvider>
                </div>
                <div class="column is-half-tablet">
                  <ValidationProvider name="Fresh" rules="required" v-slot="{ errors }">
                    <b-field label="Fresh" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                      <b-input type="number" step="any" id="fresh" v-model.number="form.fresh" placeholder="" expanded/>
                      <p class="control">
                        <b-button type="is-text" class="btn-placeholder">Kg</b-button>
                      </p>
                    </b-field>
                  </ValidationProvider>
                </div>
                <div class="column is-half-tablet" v-if="cost">
                  <ValidationProvider name="Fresh Price" rules="required" v-slot="{ errors }">
                    <b-field label="Price / Kg" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                      <p class="control">
                        <b-button type="is-text" class="btn-placeholder">Rp</b-button>
                      </p>
                      <b-input type="number" id="fresh_price" step="any" v-model.number="form.fresh_price"
                               placeholder="0"
                               expanded/>
                    </b-field>
                  </ValidationProvider>
                </div>

                <div class="column is-half-tablet">
                  <ValidationProvider name="Undersize" rules="required" v-slot="{ errors }">
                    <b-field label="Undersize" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                      <b-input placeholder="" type="number" step="any" id="undersize"
                               v-model.number="form.undersize" expanded/>
                      <p class="control">
                        <b-button type="is-text" class="btn-placeholder">Kg</b-button>
                      </p>
                    </b-field>
                  </ValidationProvider>
                </div>
                <div class="column is-half-tablet" v-if="cost">
                  <ValidationProvider name="Undersize Price" rules="required" v-slot="{ errors }">
                    <b-field label="Price / Kg" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                      <p class="control">
                        <b-button type="is-text" class="btn-placeholder">Rp</b-button>
                      </p>
                      <b-input type="number" id="undersize_price" step="any" v-model.number="form.undersize_price"
                               placeholder="0"
                               expanded/>
                    </b-field>
                  </ValidationProvider>
                </div>

                <div class="column is-half-tablet">
                  <ValidationProvider :name="$t('harvest.molting')" rules="required" v-slot="{ errors }">
                    <b-field :label="$t('harvest.molting')" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                      <b-input type="number" step="any" id="molting" v-model.number="form.molting" placeholder=""
                               expanded/>
                      <p class="control">
                        <b-button type="is-text" class="btn-placeholder">Kg</b-button>
                      </p>
                    </b-field>
                  </ValidationProvider>
                </div>
                <div class="column is-half-tablet" v-if="cost">
                  <ValidationProvider name="Molting Price" rules="required" v-slot="{ errors }">
                    <b-field label="Price / Kg" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                      <p class="control">
                        <b-button type="is-text" class="btn-placeholder">Rp</b-button>
                      </p>
                      <b-input type="number" id="molting_price" step="any" v-model.number="form.molting_price"
                               placeholder="0"
                               expanded/>
                    </b-field>
                  </ValidationProvider>
                </div>

                <div class="column is-half-tablet">
                  <ValidationProvider :name="$t('harvest.defective')" rules="required" v-slot="{ errors }">
                    <b-field :label="$t('harvest.defective')" step="any" :type="errors[0] ? 'is-danger' : ''"
                             :message="errors[0]">
                      <b-input type="number" id="defective" v-model.number="form.defective"
                               placeholder=""
                               expanded/>
                      <p class="control">
                        <b-button type="is-text" class="btn-placeholder">Kg</b-button>
                      </p>
                    </b-field>
                  </ValidationProvider>
                </div>
                <div class="column is-half-tablet" v-if="cost">
                  <ValidationProvider name="Defective Price" rules="required" v-slot="{ errors }">
                    <b-field label="Price / Kg" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                      <p class="control">
                        <b-button type="is-text" class="btn-placeholder">Rp</b-button>
                      </p>
                      <b-input type="number" id="defective_price" step="any" v-model.number="form.defective_price"
                               placeholder="0"
                               expanded/>
                    </b-field>
                  </ValidationProvider>
                </div>

                <div class="column is-half-tablet">
                  <ValidationProvider :name="$t('harvest.hollow')" rules="required" v-slot="{ errors }">
                    <b-field :label="$t('harvest.hollow')" step="any" :type="errors[0] ? 'is-danger' : ''"
                             :message="errors[0]">
                      <b-input type="number" id="hollow" v-model.number="form.hollow" placeholder=""
                               expanded/>
                      <p class="control">
                        <b-button type="is-text" class="btn-placeholder">Kg</b-button>
                      </p>
                    </b-field>
                  </ValidationProvider>
                </div>
                <div class="column is-half-tablet" v-if="cost">
                  <ValidationProvider name="Hollow Price" rules="required" v-slot="{ errors }">
                    <b-field label="Price / Kg" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                      <p class="control">
                        <b-button type="is-text" class="btn-placeholder">Rp</b-button>
                      </p>
                      <b-input type="number" id="hollow_price" step="any" v-model.number="form.hollow_price"
                               placeholder="0"
                               expanded/>
                    </b-field>
                  </ValidationProvider>
                </div>

                <div class="column is-half-tablet">
                  <ValidationProvider :name="$t('harvest.others')" rules="required" v-slot="{ errors }">
                    <b-field :label="$t('harvest.others')" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                      <b-input type="number" step="any" id="others" v-model.number="form.others" placeholder=""
                               expanded/>
                      <p class="control">
                        <b-button type="is-text" class="btn-placeholder">Kg</b-button>
                      </p>
                    </b-field>
                  </ValidationProvider>
                </div>
                <div class="column is-half-tablet" v-if="cost">
                  <ValidationProvider name="Other Price" rules="required" v-slot="{ errors }">
                    <b-field label="Price / Kg" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                      <p class="control">
                        <b-button type="is-text" class="btn-placeholder">Rp</b-button>
                      </p>
                      <b-input type="number" id="others_price" step="any" v-model.number="form.others_price"
                               placeholder="0"
                               expanded/>
                    </b-field>
                  </ValidationProvider>
                </div>

                <div class="column is-full" v-if="cost">
                  <ValidationProvider name="Cost" rules="required" v-slot="{ errors }">
                    <b-field label="Cost" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                      <p class="control">
                        <b-button type="is-text" class="btn-placeholder">Rp</b-button>
                      </p>
                      <p class="control w-100">
                        <b-input v-model.number="form.harvest_cost" id="harvest_cost" placeholder="Input Cost"/>
                      </p>
                    </b-field>
                  </ValidationProvider>
                </div>

              </div>
            </div>
          </form>
        </ValidationObserver>

      </section>
      <footer class="has-text-right">
        <b-button type="is-secondary is-light" @click="$emit('close')" v-if="isModal">{{ $t('cancel') }}</b-button>
        <b-button type="is-primary is-gradient" class="ml-2" @click="handleSubmit">{{ $t('save') }}</b-button>
      </footer>
    </div>
  </div>
</template>

<script>
import SgSelect from "@/components/Sg/SgSelect";
import {mapActions, mapGetters, mapState} from "vuex";
import {CometChat} from "@cometchat-pro/chat";

export default {
  name: "InputStockHarvest",
  components: {SgSelect},
  props: {
    isModal: {
      type: Boolean,
      default: false
    },
    type: String,
    defaultValue: {
      type: Object,
      required: false,
    },
    multiple: Boolean,
  },
  computed: {
    ...mapGetters('farm', [
      'isPro',
    ]),

    title() {
      return this.type ? this.$t(this.type + '.' + this.type) : this.$t('stock.stock') + ' ' + this.$t('and') + ' ' + this.$t('harvest.harvest')
    },
    canAddStock() {
      return this.$store.getters["farm/canAddStock"]
    },
    canEditStock() {
      return this.$store.getters["farm/canEditStock"]
    },
    canAddHarvest() {
      return this.$store.getters["farm/canAddHarvest"]
    },
    canEditHarvest() {
      return this.$store.getters["farm/canEditHarvest"]
    },

    ...mapState('setting', [
      'cost',
    ]),

    selectedFarm() {
      return this.$store.state.farm.selectedFarm
    },
    filteredPonds() {
      if (this.defaultValue) return this.selectedFarm.ponds.filter(e => e.pond_id === this.defaultValue.pond_id)
      if (this.form.block && this.selectedFarm.ponds) {
        if (this.multiple && this.form.type === 'stock')
          return this.selectedFarm.ponds.filter(e => e.block_id === this.form.block.block_id && e.status <= 1)
        return this.selectedFarm.ponds.filter(e => e.block_id === this.form.block.block_id && e.status === (this.form.type === 'stock' ? 1 : 2))
      }
      else return []
    },
    ...mapState('user', [
      'cometChatUser',
      'cometChatLoggedIn',
    ]),
    guid() {
      return `sgara${this.selectedFarm.farm_id}`
    },
  },
  data: () => {
    return {
      form: {
        block: null,
        pond: null,
        type: 'stock',
        stock: null,
        mbw: null,
        size: null,
        pl_count: null,
        file: [],
      },

      cycle_date: '-',
      stock_date: '-',

      file: [],
      avg_pl: null,

      showInformation: false,
    }
  },
  watch: {
    selectedFarm: {
      deep: true,
      handler() {
        this.resetForm()
      }
    },
    'form.type'() {
      this.resetForm()
    },
  },
  created() {
    this.resetForm()
  },
  methods: {
    ...mapActions('farm', [
      'countPl'
    ]),

    handleChangeBag() {
      let bag = this.form.bag && this.form.bag > 0 ? this.form.bag : 1
      this.form.pl_count = this.avg_pl * bag
    },

    async handleChangeFile() {
      for (let i = 0; i < this.file.length; i++) {
        if (!this.file[i].pl) {
          this.$loading()
          await this.countPl({
            image_url: this.file[i],
            hatch_color: 'black',
          }).then((res) => {
            this.form.file.push(res.data)
            this.file[i].pl = res.data
          }).finally(() => {
            this.$loading(false)
          })
        }
      }

      this.avg_pl = this.form.file.reduce((a, b) => a + b[0].total_pl, 0) / this.form.file.length
      this.form.pl_count = this.avg_pl
    },

    deletePhoto(key) {
      this.form.file.splice(key, 1)
      this.file.splice(key, 1)
    },

    handleSubmit() {
      this.$refs[this.form.type === 'stock' ? 'form-stock' : 'form-harvest'].validate().then(async success => {
        if (success) {
          this.$loading()

          let pond = this.form.pond
          let date = null
          let text = ''

          if (this.form.type === 'stock') {
            text += 'Jumlah Benur Aktual ' + this.form.pl_count + ' dan Netto ' + this.form.netto
            if (this.defaultValue && this.defaultValue.stock_id) {
              let action = this.isPro ? 'farm/updateStock' : 'farm/updateStockCycle'

              await this.$store.dispatch(action, {
                stock_date: this.$timestamp(this.form.stock_date),
                pl_count: this.form.pl_count,
                netto: this.form.netto,
                pl_source: this.form.pl_source,
                stock_cost: this.form.stock_cost || 0,
                stock_id: this.defaultValue.stock_id,
                cycle_id: this.defaultValue.cycle_id,
                farm_id: this.defaultValue.farm_id,
                block_id: this.defaultValue.block_id,
                pond_id: this.defaultValue.pond_id,
                created_at: this.defaultValue.created_at,
              })
            } else {
              if (this.multiple && pond) {
                for (let i = 0; i < pond.length; i++) {
                  await this.addStock(pond[i])
                }
              } else {
                await this.addStock(pond)
              }
              await this.$store.dispatch('farm/getPond')
            }
            date = this.$dateFormat(this.form.stock_date)
          } else if (this.form.type === 'harvest') {
            text += ' MBW ' + this.form.mbw + ' dan total udang Fresh ' + this.form.fresh + ' Kg'
            if (this.defaultValue && this.defaultValue.stock_id) {
              await this.$store.dispatch('farm/updateHarvest', {
                stock_id: this.defaultValue.stock.stock_id,
                harvest_date: this.$timestamp(this.form.harvest_date),
                is_harvest_total: this.form.harvest_type === 'Total Harvest',
                size: this.form.size,
                mbw: this.form.mbw,
                fresh: this.form.fresh,
                fresh_price: this.form.fresh_price,
                molting: this.form.molting,
                molting_price: this.form.molting_price,
                undersize: this.form.undersize,
                undersize_price: this.form.undersize_price,
                defective: this.form.defective,
                defective_price: this.form.defective_price,
                hollow: this.form.hollow,
                hollow_price: this.form.hollow_price,
                others: this.form.others,
                others_price: this.form.others_price,
                harvest_cost: this.form.molting * this.form.molting_price +
                    this.form.fresh * this.form.fresh_price +
                    this.form.defective * this.form.defective_price +
                    this.form.hollow * this.form.hollow_price +
                    this.form.others * this.form.others_price +
                    this.form.undersize * this.form.undersize_price,
                created_at: this.defaultValue.created_at,
                harvest_id: this.defaultValue.harvest_id,
              })
            } else {
              let stock = await this.$store.dispatch('farm/getActiveStock', pond.pond_id)

              if (stock) {
                await this.$store.dispatch('farm/addHarvest', {
                  stock_id: stock.stock_id,
                  fresh: this.form.fresh,
                  fresh_price: this.form.fresh_price,
                  harvest_date: this.$timestamp(this.form.harvest_date),
                  is_harvest_total: this.form.harvest_type === 'Total Harvest',
                  mbw: this.form.mbw,
                  molting: this.form.molting,
                  molting_price: this.form.molting_price,
                  size: this.form.size,
                  undersize: this.form.undersize,
                  undersize_price: this.form.undersize_price,
                  defective: this.form.defective,
                  defective_price: this.form.defective_price,
                  hollow: this.form.hollow,
                  hollow_price: this.form.hollow_price,
                  others: this.form.others,
                  others_price: this.form.others_price,
                  harvest_cost: this.form.harvest_cost,
                  created_at: this.$timestamp(),
                })
                await this.$store.dispatch('farm/getPond')
              }
            }
            date = this.$dateFormat(this.form.harvest_date)
          }

          text += " " + pond.name + ' tanggal ' + date
          this.sendMessage(text)

          this.resetForm()
          this.$loading(false)
          this.$emit('close')
          this.$emit('submit', {
            type: this.type,
            isAdd: !this.defaultValue,
          })
          this.$store.dispatch('farm/refreshOverview')
        }
      })
    },

    async addStock(pond) {
      let cycle = await this.$store.dispatch('farm/getActiveCycle', pond.pond_id)

      if (!cycle) {
        // add cycle here
        let res = await this.$store.dispatch('farm/startCycle', {
          pond_id: pond.pond_id,
          start_date: this.$timestamp(this.form.stock_date)
        })

        if (res.data)
          cycle = {
            cycle_id: res.data.id
          }
      }

      if (cycle) {
        await this.$store.dispatch('farm/addStock', {
          stock_date: this.$timestamp(this.form.stock_date),
          pl_count: this.form.pl_count,
          netto: this.form.netto,
          pl_source: this.form.pl_source,
          farm_id: this.selectedFarm.farm_id,
          block_id: this.form.block.block_id,
          pond_id: pond.pond_id,
          cycle_id: cycle.cycle_id,
          stock_cost: this.form.stock_cost,
          created_at: this.$timestamp(),
        })
      }
    },

    resetForm() {
      if (this.multiple) this.form.pond = []

      if (this.type) {
        this.form.type = this.type
      } else {
        if (!this.canAddStock) this.form.type = 'harvest'
      }

      if (this.defaultValue) {
        this.form.block = this.selectedFarm.blocks.find(e => e.block_id === this.defaultValue.block_id)
        this.form.pond = this.filteredPonds.find(e => e.pond_id === this.defaultValue.pond_id)
        if (this.form.type === 'stock') {
          this.form.stock_date = new Date(this.defaultValue.stock_date)
          this.form.netto = this.defaultValue.netto
          this.form.pl_count = this.defaultValue.pl_count
          this.form.shrimp_count = this.defaultValue.shrimp_count
          this.form.pl_count = this.defaultValue.pl_count
          this.form.pl_source = this.defaultValue.pl_source
          this.form.stock_cost = this.defaultValue.stock_cost
        } else if (this.form.type === 'harvest') {
          this.cycle_date = this.$dateFormat(this.defaultValue.cycle.start_date)
          this.stock_date = this.$dateFormat(this.defaultValue.stock.stock_date)
          this.form.harvest_date = new Date(this.defaultValue.harvest_date)
          this.form.harvest_type = this.defaultValue.is_harvest_total ? 'Total Harvest' : 'Partial Harvest'
          this.form.size = this.defaultValue.size
          this.form.mbw = this.defaultValue.mbw
          this.form.fresh = this.defaultValue.fresh
          this.form.fresh_price = this.defaultValue.fresh_price
          this.form.undersize = this.defaultValue.undersize
          this.form.undersize_price = this.defaultValue.undersize_price
          this.form.molting = this.defaultValue.molting
          this.form.molting_price = this.defaultValue.molting_price
          this.form.defective = this.defaultValue.defective
          this.form.defective_price = this.defaultValue.defective_price
          this.form.hollow = this.defaultValue.hollow
          this.form.hollow_price = this.defaultValue.hollow_price
          this.form.others = this.defaultValue.others
          this.form.others_price = this.defaultValue.others_price
          this.form.harvest_cost = this.defaultValue.harvest_cost
        }
      } else {
        this.form.block = null
        this.form.pond = null
      }
    },
    async handleChangePond(pond) {
      this.$loading()

      this.form.stock = null
      this.stock_date = '-'
      this.cycle_date = '-'

      let cycle = await this.$store.dispatch('farm/getActiveCycle', pond.pond_id)
      let stock = await this.$store.dispatch('farm/getActiveStock', pond.pond_id)

      if (cycle) this.cycle_date = this.$dateFormat(cycle.start_date)
      if (stock) {
        this.form.stock = stock
        this.stock_date = this.$dateFormat(stock.stock_date)
      }

      this.$loading(false)
    },

    handleSizeChange() {
      this.form.mbw = 1000 / this.form.size
    },
    handleMBWChange() {
      this.form.size = 1000 / this.form.mbw
    },

    sendMessage(text) {
      if (this.cometChatLoggedIn) {
        let tag = this.form.type === 'stock' ? 'Stok' : 'Panen'

        let receiverID = this.guid;
        let receiverType = CometChat.RECEIVER_TYPE.GROUP;

        let messageText = `<b>${this.cometChatUser.name}</b>` + " baru saja menginput data " + tag + " " + text;
        let textMessage = new CometChat.TextMessage(receiverID, messageText, receiverType);

        textMessage.setTags([tag])

        CometChat.sendMessage(textMessage).then(
            message => {
              console.log("Message sent successfully:", message);
            }, error => {
              console.log("Message sending failed with error:", error);
            }
        );
      }
    },
  }
}
</script>

<style scoped lang="scss">
.stock-modal {
  &.card {
    width: 720px !important;
    max-width: 100%;
  }

  .carousel .carousel-item .image img {
    margin: 0 auto;
  }

  .pl-image {
    position: relative;
  }

  .pl-overlay {
    display: flex;
    left: 0;
    right: 0;
    top: 0;
    align-items: center;
    position: absolute;
    justify-content: space-between;
    background-color: #FFFFFF;
    padding-left: 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, .08);
    border-radius: .5rem .5rem 0 0;
    overflow: hidden;

    .button {
      border-radius: 0;
    }
  }
}
</style>
<style lang="scss">
.tooltip-count-pl {
  .tooltip-content {
    width: 400px !important;
  }
}
</style>
